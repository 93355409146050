// COLORS
$bg-color: #202224;
$bg-color-light: #292929;
$text-color: #e7e7e7;
$text-color-invert: #202224;
$black-text-color: #202224;
$navbg-color: rgba(255, 170, 0, 0.117);
$border-color: #c19641;
$yellow: #FFC824;
$light-yellow: rgb(255, 220, 92);

// PRESETS
$yellow-border: 4px solid #c19641;
$yellow-border-2px: 2px solid #c19641; 
$yellow-border-1px: 1px solid #c19641;

// GRADIENT BGS
.bg-grey {
    background: rgb(14,14,14);
    background: linear-gradient(10deg, rgba(14,14,14,1) 0%, rgba(40,46,51,1) 100%);
}

.bg-red {
    background: rgb(29,0,0) !important;
    background: linear-gradient(10deg, rgba(29,0,0,1) 0%, rgba(71,15,15,1) 100%) !important;
}

.bg-blue {
    background: rgb(0,3,29) !important;
    background: linear-gradient(10deg, rgba(0,3,29,1) 0%, rgba(15,34,71,1) 100%) !important;
}

.bg-pink {
    background: rgb(21,0,29) !important;
    background: linear-gradient(10deg, rgba(21,0,29,1) 0%, rgba(70,15,71,1) 100%) !important;
}

.bg-yellow {
    background: rgb(29,17,0) !important;
    background: linear-gradient(10deg, rgba(29,17,0,1) 0%, rgba(71,59,15,1) 100%) !important;
}

.bg-green {
    background: rgb(2,29,0) !important;
    background: linear-gradient(10deg, rgba(2,29,0,1) 0%, rgba(18,71,15,1) 100%) !important;
}
