@import "vars";

* {
    color: $text-color;
    font-family: 'Manrope', sans-serif !important;
}

body {
    background: url(../../public/images/bg.png) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    margin: 0;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex-grow: 1;
}

.chat-list-height-control {
    height: calc(100vh - 210px);
}

.chat-height-control {
    height: calc(100vh - 276px);
}

a {
    color: $text-color !important;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #202224;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $yellow;
}

.nowrap {
    white-space: nowrap;
}

.content-wraper {
    display: flex;
    min-height: calc(100vh - 130px);
    flex-direction: column;
}

.content {
    font-size: 18px;
}

.footer-wraper {
    margin-top: auto;
}

.navbar {
    background: rgb(40,40,40);
    background: linear-gradient(20deg, rgba(40,40,40,1) 0%, rgba(74,27,0,1) 100%);
    //background-color: #202224C9;
    //border-bottom: $yellow-border-2px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
}



.offcanvas {
    background-color: $bg-color;
}

.offcanvas-title {
    text-transform: uppercase;
}


.header {
    //background-color: $navbg-color;
    //background-color: rgba(255, 170, 0, 0.05);
    font-size: 18px !important;
    //border-bottom: $yellow-border;
}

.header-link {
    color: $text-color !important;
}

.navbar-brand {
    margin: 8px !important;
}

.container-decreased-fluid {
    width: 90% !important;
}

.navbar-element {
    margin: 8px !important;
}

.nav-link {
    text-transform: uppercase !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
}

.nav-link:hover {
    color: #ffd148 !important;
}

.navbar-toggler {
    border-color: #dcdcdc !important;
}

.navbar-toggler-icon {
    background-image: url("../../public/images/navbartogglericon.png") !important;
}

.nickname {
    text-decoration: none !important;
    // font-weight: bolder !important;

    &:hover {
        color: $yellow !important;
    }
}

.inverted-text-color {
    color: $text-color-invert !important;
}



.btn {
    /* font-size: 18px !important;
    border: none !important;
    background-color: #545454;
    color: $text-color !important;
    text-transform: uppercase !important;
    padding-right: 20px;
    padding-left: 20px;*/


    border: 2px solid #c19641;
    background-color: $bg-color;
    color: $text-color !important;
    text-transform: uppercase !important;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 700;

    &:hover {
        background-color: #202224;
        border: 2px solid $yellow;
        //background-color: #444444;
        //color: $black-text-color !important;
    }

    &:focus {
        background-color: $bg-color !important;
        border: 2px solid $yellow !important;
    }

    &:active {
        background-color: $bg-color !important;
        border: 2px solid $yellow !important;
    }

    &:disabled {
        border: 2px solid #c19641;
        background-color: $bg-color;
        font-weight: 500;
        color: gray;
    }
}

.btn-primary {
    border: 2px solid $yellow;
    background-color: #7E5311;

    &:hover {
        background-color: #AB741E;
        border: 2px solid $yellow;
    }

    &:focus {
        background-color: #AB741E !important;
        border: 2px solid $yellow !important;
    }

    &:active {
        background-color: #AB741E !important;
        border: 2px solid $yellow !important;
    }
    //color: $black-text-color !important;
    //font-weight: bold !important;
}

.btn-danger {
    border: 2px solid #FF4E3F;
    background-color: #670D0D;

    &:hover {
        border: 2px solid #FF4E3F;
        background-color: #8C1313;
    }

    &:focus {
        border: 2px solid #FF4E3F !important;
        background-color: #8C1313 !important;
    }

    &:active {
        border: 2px solid #FF4E3F !important;
        background-color: #8C1313 !important;
    }
}

.scale-on-hover {
    transition: 0.1s !important;

    &:hover {
        transform: scale(1.05) !important;
    }
}

.icon-btn {
    transition: 0.1s !important;
    cursor: pointer;

    &:hover {
        transform: scale(1.15) !important;
    }
}




.card {
    border: 1px solid #c19641;
    background-color: $bg-color;
    /*background-color: #2C2C2C9F;
    border: none;*/
    padding: 0px;
    padding-left: 4px;
    padding-right: 4px;
}

@media screen and (min-width: 768px) {
    .chat-list-md-max-width {
        max-width: 220px;
    }
}

.content_video_box {
    text-align: left !important;
    align-items: center !important;
    /* justify-content: center; */
    display: flex;
}

.content_video_box_text {
    font-size: 64px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}


.yt-trailer {
    width: 100%;
    aspect-ratio: 640 / 360;
    //max-width: 900px;
}

.screens-corusel {
    width: 100%;
    aspect-ratio: 16 / 9;
    max-width: 800px;
}





.modal-exit-icon {
    background-image: url("../../public/images/navbartogglericon.png") !important;
}

.modal-dark {
    background-color: #1c1c1c !important;
    background-image: none !important;
    border: $yellow-border;
    border-radius: 12px;
}

.modal-header {
    border: none !important;
}

.loader {
    //margin: 50px auto;
    width: 48px;
    height: 48px;
    position: relative;
    animation: rotation 1s infinite linear;
    background-image: url("../../public/images/loading.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    box-sizing: border-box;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}




input {
    /*border: none;
    border-bottom: 3px solid #c19641;
    background-color: transparent !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 100% !important;
    color: #afafaf;*/

    //width: 100% !important;
    border: 1px solid #c19641;
    border-radius: 8px;
    background-color: $bg-color !important;
    padding: 7px;
    color: $text-color;
}

input:focus {
    border: 1px solid #c19641;
    border-color: $yellow;
    color: $text-color;
}

.input-group-text {
    border-radius: 8px;
    border-color: $border-color;
    background-color: $border-color;
    color: $bg-color !important;
}

.form-control {
    @extend input;
}



.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: rgb(82, 82, 82);
}

.continue-with-google {
    background-color: #f3f3f3 !important;
    border-radius: 8px !important;
    border: none !important;
    width: 100% !important;
    height: 48px !important;
    text-decoration: none !important;
    font-weight: bolder !important;
    color: #575757 !important;
    text-align: center !important;
    vertical-align: middle !important;
}

.forgot-password-text {
    width: 100%;
    text-align: right !important;
}



.content-box {
    border: none;
    border-radius: 8px;
    background-color: $bg-color;
    padding: 20px;
    //margin-top: 20px;
    margin-bottom: 10px;
    @extend .bg-grey;
}

.content-box-image {
    @extend .content-box;
    background-image: url("../../public/images/wallpaper.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 1280 / 720;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-box-mini {
    border: none;
    border-radius: 8px;
    background-color: $bg-color-light;
    padding: 10px;
    @extend .bg-grey;
    //margin-top: 14px;
    //margin-bottom: 14px;
}



/*.bg-red {
    background-color: #3a2121 !important;
}

.bg-blue {
    background-color: #212c3a !important;
}

.bg-pink {
    background-color: #3a2139 !important;
}

.bg-yellow {
    background-color: #3a3321 !important;
}

.bg-green {
    background-color: #213a2d !important;
}*/


.block-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.block-title-mini {
    font-weight: bold;
    //font-size: 20px !important;
    // text-transform: uppercase;
    margin-bottom: 10px;
}

.margin-x {
    margin-left: 10px;
    margin-right: 10px;
}

.footer-border {
    border-top: 4px solid #c19641;
}

.rounded-border {
    border-radius: 8px;
}

.big-nickname {
    font-size: 28px;
    font-weight: bold;
}

.big-avatar{
    border-radius: 6px;
}

.profile-tag {
    border-radius: 6px;
    background-color: darkred;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.avatar-option {
    max-width: 64px !important;
}

.inventory-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto auto;
}

@media (max-width: 720px) {
    .inventory-grid {
        grid-template-columns: auto auto auto;
    }
}

.inventory-item-common {
    border: none;
    border-radius: 4px;
    background-color: $bg-color-light;
    padding: 4px;
    margin: 4px;
}

.form-input-box {
    border: none;
    border-radius: 8px;
    background-color: #2b2b2b;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.form-input-box-label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #898989 !important;
}

.inventory-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto auto;
}

@media (max-width: 1020px) {
    .inventory-grid {
        grid-template-columns: auto auto auto;
    }
}

.inventory-grid-mini {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto;
}

.inventory-item {
    position: relative;
    text-align: center;
    border: 2px solid #4D4D4D;
    border-radius: 8px;
    background-color: $bg-color-light;
    padding: 8px;
    margin: 4px;
}

.inventory-item-img {
    width: 90px;
    height: 90px;
    //border-radius: 4px;
}

.inventory-item-count {
    position: absolute;
    bottom: 1px;
    right: 3px;
    background-color: $bg-color-light;
    padding-left: 6px;
    border-radius: 6px;
}

.inventory-item-selected {
    position: absolute;
    bottom: 41px;
    right: 9px;
    background-color: #00A51F;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 6px;
    user-select: none;
}

.level-border-container {
    position: relative;
    text-align: center;
    color: white;
}

.level-border-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.chats-list-item {
    background-color: #313131;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    user-select: none;
    cursor: pointer;
}

.chats-list-item-selected {
    @extend .chats-list-item;
    background-color: #545454;
    cursor: default;
}


.img-container {
    position: relative;
    text-align: center;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notifications-container {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.notification {
    background-color: #1c1c1c;
    border: $yellow-border;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.25);
    max-width: 420px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    animation-duration: 0.5s;
    animation-name: animate-fade;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
        transform: translate(0, -30px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

.notification-error {
    background-color: #5B0000;
    border-color: #D80000;
}
 
.notification-warning {
    background-color: #5B4700;
    border-color: #E2B000;
}

.notifications-clear-container {
    max-width: 420px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    padding-right: 2px;
}

.notifications-clear {
    cursor: pointer;
    text-shadow: 1px 1px 2px black;
}

.logs-blue-text {
    color: #A3D8FF;
    text-decoration: underline;
    text-decoration-color: #A3D8FF;
}

.logs-bg-eror {
    background: #402626;
}

.logs-bg-info{
    background:$bg-color-light;
}

.logs-bg-tag {
    background: #6D603E;
}